body {
  padding: 0;
  margin: 0;
  font-size: 2.5rem;
  line-height: 25px;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  font-family: 'Outfit' !important ;
}
.ta-text {
	font-size: 1.5rem;
	line-height: 25px;
	font-family: 'Nato Sans', sans-serif !important;
}

html{
  height: 100%;
}